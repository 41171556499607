/* globals GATSBY_MNAAS_PORTAL_VCO_ID */

import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import ReactTooltip from "react-tooltip"
import { isEmpty } from "lodash"
import { Typography } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"
import TelstraTheme from "../../gatsby-theme-material-ui-top-layout/theme.js"
import Layout from "../../components/layout/layout.js"
import TableCustom from "../../components/common/table-custom/index.js"
import ToastContent from "../../components/common/toast-content.js"
import "react-toastify/dist/ReactToastify.css"
import { toast, ToastContainer } from "react-toastify"
import getManagedTenancyColumns from "../../components/activations/get-managed-tenancy-columns.js"
import ModalCustom from "../../components/common/modal-custom/index.js"
import CreateManagedTenancyModalChildren from "../../components/activations/create-managed-tenancy-modal-children/index.js"
import httpReqListTenancies from "../../services/activations/http-req-list-tenancies"
import makeManagedTenanciesDbToRuntime from "../../services/activations/make-managed-tenancies-db-to-runtime.js"
import makeMenuOptions from "../../services/activations/make-menu-options.js"
import httpReqDeleteTenancy from "../../services/activations/http-req-delete-tenancy.js"
import httpReqGetTenancy from "../../services/activations/http-req-get-tenancy.js"
import makeVelocloudTenancyDbToRuntime from "../../services/activations/make-velocloud-tenancy-db-to-runtime.js"
import { getUser } from "../../utilities/userManager"
import envUtil from "../../utilities/env-util"
import { useDispatch } from "react-redux"
import {
  enableLoading,
  disableLoading,
} from "../../components/common/state/loadingSlice.js"

// Define the initial state for the create managed tenancy modal

const makeCreateManagedTenancyModalInitialState = () => ({
  modalOpen: false,
  mode: "CREATE",
  uuid: "",
  customerNetworkCode: "",
  tenancyNameAffix: "",
  businessName: "",
  customerIdentifier: "",
  description: "",
  tenancyProfileType: "",
  contactEmail: "",
  username: "",
  vendorId: null,
  dealerCode: "",
  vcoUrl: null,
  softwareImage: "latest",
  gatewayPoolId: GATSBY_MNAAS_PORTAL_VCO_ID === 309 ? 6 : 12,
  template: "Adapt S1",
})

const IndexPage = () => {
  // Use state for createManagedTenancyModal
  const dispatch = useDispatch()
  const [createManagedTenancyModal, setCreateManagedTenancyModal] = useState(
    makeCreateManagedTenancyModalInitialState()
  )
  // Use state for managedTenanciesTableData
  const [managedTenanciesTableData, setManagedTenanciesTableData] = useState([])

  // Create the state for hidden columns
  const [hiddenColumns, setHiddenColumns] = useState(["uuid"])

  // Show/Hide columns click handler
  const showHideColumnsClickHandler = () => {
    isEmpty(hiddenColumns) ? setHiddenColumns(["uuid"]) : setHiddenColumns([])
  }

  // Create managed tenancy modal open handler
  const createManagedTenancyModalOpenHandler = () => {
    setCreateManagedTenancyModal({
      ...makeCreateManagedTenancyModalInitialState(),
      modalOpen: true,
    })
  }

  // Set modal open - create managed tenancy modal
  const setModalOpenCreateManagedTenancy = (modalOpen) => {
    setCreateManagedTenancyModal({
      ...createManagedTenancyModal,
      modalOpen,
    })
  }

  // Input text handler
  const createManagedTenancyModalInputTextHandler = (evt) => {
    setCreateManagedTenancyModal({
      ...createManagedTenancyModal,
      [evt.target.name]: evt.target.value,
    })
  }

  // Input select handler
  const createManagedTenancyModalInputSelectHandler = (evt) => {
    setCreateManagedTenancyModal({
      ...createManagedTenancyModal,
      [evt.target.name]: evt.target.value,
    })
  }

  // Update managed tenancies table
  const refreshTenanciesTableData = async () => {
    try {
      // List all managed tenancies from CMDB
      dispatch(enableLoading())
      if (envUtil.getPlatform() === "pcf") getUser()

      const managedTenanciesDb = await httpReqListTenancies()
      // Update the table
      setManagedTenanciesTableData(
        makeManagedTenanciesDbToRuntime(managedTenanciesDb)
      )
    } catch (error) {
      console.log(error)
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Update tenancy modal from CMDB
  const refreshTenancyModalData = async ({ uuid, mode }) => {
    try {
      // Get tenancy from MongoDB by UUID
      dispatch(enableLoading())
      if (envUtil.getPlatform() === "pcf") getUser()

      const managedTenancyDb = await httpReqGetTenancy(uuid)
      // Convert velocloud tenancy to runtime
      const velocloudTenancy = makeVelocloudTenancyDbToRuntime(managedTenancyDb)
      // Update the modal
      setCreateManagedTenancyModal({
        mode,
        modalOpen: true,
        uuid: uuid,
        customerNetworkCode: velocloudTenancy.customerNetworkCode,
        tenancyNameAffix: velocloudTenancy.tenancyNameAffix,
        businessName: velocloudTenancy.businessName,
        customerIdentifier: velocloudTenancy.customerIdentifier,
        description: velocloudTenancy.description,
        tenancyProfileType: velocloudTenancy.tenancyProfileType,
        contactEmail: velocloudTenancy.contactEmail,
        username: velocloudTenancy.username,
        vendorId: velocloudTenancy.vendorId,
        dealerCode: velocloudTenancy.dealerCode,
        softwareImage: "latest",
        gatewayPoolId: velocloudTenancy.gatewayPoolId,
        template: velocloudTenancy.template,
        vcoUrl: velocloudTenancy.vcoUrl,
      })
    } catch (error) {
      console.log(error)
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Table row click handler
  const tableRowClickHandler = ({ row }) => {
    refreshTenancyModalData({ uuid: row.original.uuid, mode: "VIEW" })
  }

  // Delete button click handler
  const deleteButtonClickHandler = ({ evt, original }) => {
    evt.stopPropagation()
    refreshTenancyModalData({ uuid: original.uuid, mode: "DELETE" })
  }

  // Delete tenancy confirmed handler
  const deleteTenancyConfirmedHandler = async (uuid) => {
    try {
      // Close the modal
      setCreateManagedTenancyModal({
        ...createManagedTenancyModal,
        modalOpen: false,
      })

      // Define the request body
      const reqBodyDeleteTenancy = {
        inputParameters: [
          {
            name: "uuid",
            value: uuid,
          },
        ],
      }

      // Execute the workflow to delete the tenancy
      dispatch(enableLoading())
      await httpReqDeleteTenancy(reqBodyDeleteTenancy)

      // Display a success message
      toast.info(<ToastContent>Success - the tenancy was deleted</ToastContent>)

      // Reload the table data after 1 second
      setTimeout(() => {
        refreshTenanciesTableData()
      }, 1000)
    } catch (error) {
      // Display an error message
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Rebuild the tooltip when the state is updated
  useEffect(() => {
    ReactTooltip.rebuild()
  })

  // Component did mount
  useEffect(() => {
    refreshTenanciesTableData()
  }, [])

  return (
    <Layout menuOptions={makeMenuOptions()}>
      <ThemeProvider theme={TelstraTheme}>
        <Helmet title="MNaaS Activations - Tenancies" />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          className="toast-container"
          toastClassName="toast-info"
          bodyClassName="toast-body"
        />
        <ReactTooltip className="tooltip-custom" multiline={true} />
        <div className="app-wrapper">
          <Typography variant="h2" gutterBottom={true}>
            MNaaS Activations
          </Typography>
          <TableCustom
            title="Managed Tenancies"
            columns={getManagedTenancyColumns({
              deleteButtonClickHandler,
              updateButtonClickHandler: null,
            })}
            data={managedTenanciesTableData}
            tableRowClickHandler={tableRowClickHandler}
            addButtonVisible={false}
            addButtonClickHandler={createManagedTenancyModalOpenHandler}
            addButtonData={null}
            refreshButtonVisible={true}
            refreshButtonClickHandler={refreshTenanciesTableData}
            hiddenColumns={hiddenColumns}
            showHideColumnsClickHandler={showHideColumnsClickHandler}
            initialState={{
              pageIndex: 0,
              sortBy: [
                {
                  id: "createdAt",
                  desc: true,
                },
              ],
            }}
          ></TableCustom>
          <ModalCustom
            modalOpen={createManagedTenancyModal.modalOpen}
            setModalOpen={setModalOpenCreateManagedTenancy}
            modalTitle="Managed Tenancy"
          >
            <CreateManagedTenancyModalChildren
              setModalOpen={setModalOpenCreateManagedTenancy}
              createManagedTenancyModalInputTextHandler={
                createManagedTenancyModalInputTextHandler
              }
              createManagedTenancyModalInputSelectHandler={
                createManagedTenancyModalInputSelectHandler
              }
              deleteTenancyConfirmedHandler={deleteTenancyConfirmedHandler}
              createManagedTenancy={null}
              mode={createManagedTenancyModal.mode}
              uuid={createManagedTenancyModal.uuid}
              businessName={createManagedTenancyModal.businessName}
              customerIdentifier={createManagedTenancyModal.customerIdentifier}
              customerNetworkCode={
                createManagedTenancyModal.customerNetworkCode
              }
              tenancyNameAffix={createManagedTenancyModal.tenancyNameAffix}
              description={createManagedTenancyModal.description}
              tenancyProfileType={createManagedTenancyModal.tenancyProfileType}
              contactEmail={createManagedTenancyModal.contactEmail}
              username={createManagedTenancyModal.username}
              vendorId={createManagedTenancyModal.vendorId}
              dealerCode={createManagedTenancyModal.dealerCode}
              vcoUrl={createManagedTenancyModal.vcoUrl}
              softwareImage={createManagedTenancyModal.softwareImage}
              gatewayPoolId={createManagedTenancyModal.gatewayPoolId}
              template={createManagedTenancyModal.template}
            />
          </ModalCustom>
        </div>
      </ThemeProvider>
    </Layout>
  )
}

export default IndexPage
