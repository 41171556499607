/* globals GATSBY_MNAAS_PORTAL_VCO_ID */

import React from "react"
import { Button } from "@material-ui/core"
import InputTextCustom from "../../common/input-text-custom"
import InputSelectCustom from "../../common/input-select-custom"
import makeInputSelected from "../../../utilities/make-input-selected"
import { TrendingUpRounded } from "@material-ui/icons"

const CreateManagedTenancyModalChildren = ({
  mode,
  setModalOpen,
  createManagedTenancyModalInputTextHandler,
  createManagedTenancyModalInputSelectHandler,
  deleteTenancyConfirmedHandler,
  createManagedTenancy,
  businessName,
  customerIdentifier,
  uuid,
  description,
  tenancyProfileType,
  customerNetworkCode,
  tenancyNameAffix,
  contactEmail,
  username,
  vendorId,
  dealerCode,
  vcoUrl,
  softwareImage,
  gatewayPoolId,
  template,
}) => {
  // Make tenancyProfileType input options
  const inputOptionsTenancyProfile = [
    { value: "velocloud", label: "VeloCloud" },
  ]

  // Make gatewayPoolId input options for model
  const inputOptionsGatewayPoolIdModel = [
    { value: 6, label: "Telstra Production Partner Gateways - All" },
    { value: 7, label: "Telstra Production Partner Gateways - Plane A" },
    { value: 8, label: "Telstra Production Partner Gateways - Plane B" },
    { value: 13, label: "Telstra Production Partner Gateways - Plane C" },
  ]

  // Make gatewayPoolId input options for production
  const inputOptionsGatewayPoolIdProduction = [
    { value: 12, label: "Telstra Production Partner Gateways - All" },
    { value: 22, label: "Telstra Production Partner Gateways - Plane A" },
    { value: 23, label: "Telstra Production Partner Gateways - Plane B" },
    { value: 26, label: "Telstra Production Partner Gateways - Plane S" },
  ]

  // Make softwareImage input options
  const inputOptionsSoftwareImage = [{ value: "latest", label: "Latest" }]

  // Make template input options
  const inputOptionsTemplate = [{ value: "adaptS1", label: "Adapt S1" }]

  // Return JSX
  return (
    <div className="ags-record-modal-children">
      <form>
        <InputTextCustom
          name="businessName"
          value={businessName}
          placeholder={mode === "CREATE" ? "Business Name*" : "Business Name"}
          inputTextHandler={(evt) =>
            createManagedTenancyModalInputTextHandler(evt)
          }
          disabled={true}
        />
        <InputTextCustom
          name="customerIdentifier"
          value={customerIdentifier}
          placeholder={
            mode === "CREATE" ? "Customer Identifier*" : "Customer Identifier"
          }
          inputTextHandler={(evt) =>
            createManagedTenancyModalInputTextHandler(evt)
          }
          disabled={TrendingUpRounded}
        />
        {mode === "VIEW" || mode === "DELETE" ? (
          <InputTextCustom
            name="uuid"
            value={uuid}
            placeholder="UUID"
            inputTextHandler={(evt) =>
              createManagedTenancyModalInputTextHandler(evt)
            }
            disabled={true}
          />
        ) : null}
        {customerNetworkCode ? (
          <InputTextCustom
            name="customerNetworkCode"
            value={customerNetworkCode}
            placeholder={"4LC (Four Letter Code)"}
            inputTextHandler={(evt) =>
              createManagedTenancyModalInputTextHandler(evt)
            }
            disabled={true}
          />
        ) : null}
        {tenancyNameAffix ? (
          <InputTextCustom
            name="tenancyNameAffix"
            value={tenancyNameAffix}
            placeholder={"CFSID"}
            inputTextHandler={(evt) =>
              createManagedTenancyModalInputTextHandler(evt)
            }
            disabled={true}
          />
        ) : null}
        <InputTextCustom
          name="description"
          value={description}
          placeholder="Description"
          inputTextHandler={(evt) =>
            createManagedTenancyModalInputTextHandler(evt)
          }
          disabled={true}
        />
        <InputTextCustom
          name="tenancyProfileType"
          value={tenancyProfileType}
          placeholder={
            mode === "CREATE" ? "Tenancy Profile*" : "Tenancy Profile"
          }
          inputTextHandler={(evt) =>
            createManagedTenancyModalInputTextHandler(evt)
          }
          disabled={true}
        />
        {tenancyProfileType.toLowerCase() === "velocloud" ? (
          <>
            <InputTextCustom
              name="dealerCode"
              value={dealerCode}
              placeholder={mode === "CREATE" ? "Dealer Code*" : "Dealer Code"}
              inputTextHandler={(evt) =>
                createManagedTenancyModalInputTextHandler(evt)
              }
              disabled={true}
            />
            <InputTextCustom
              name="contactEmail"
              value={contactEmail}
              placeholder={
                mode === "CREATE" ? "Contact Email*" : "Contact Email"
              }
              inputTextHandler={(evt) =>
                createManagedTenancyModalInputTextHandler(evt)
              }
              disabled={true}
            />
            {mode === "VIEW" || mode === "DELETE" ? (
              <InputTextCustom
                name="username"
                value={username}
                placeholder="Username"
                inputTextHandler={(evt) =>
                  createManagedTenancyModalInputTextHandler(evt)
                }
                disabled={true}
              />
            ) : null}
            {mode === "VIEW" || mode === "DELETE" ? (
              <InputTextCustom
                name="vendorId"
                value={vendorId}
                placeholder="Tenancy Vendor ID"
                inputTextHandler={(evt) =>
                  createManagedTenancyModalInputTextHandler(evt)
                }
                disabled={true}
              />
            ) : null}
            <InputTextCustom
              name="vcoUrl"
              value={vcoUrl}
              placeholder="VCO URL"
              inputTextHandler={(evt) =>
                createManagedTenancyModalInputTextHandler(evt)
              }
              disabled={true}
            />
            <InputTextCustom
              name="gatewayPoolId"
              value={gatewayPoolId}
              placeholder="Gateway Pool ID"
              inputTextHandler={(evt) =>
                createManagedTenancyModalInputTextHandler(evt)
              }
              disabled={true}
            />
            <InputTextCustom
              name="template"
              value={template}
              placeholder="Template"
              inputTextHandler={(evt) =>
                createManagedTenancyModalInputTextHandler(evt)
              }
              disabled={true}
            />
          </>
        ) : null}
      </form>
      {mode === "CREATE" ? (
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="secondary"
          onClick={createManagedTenancy}
        >
          Create
        </Button>
      ) : null}
      {mode === "DELETE" ? (
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="primary"
          onClick={() => deleteTenancyConfirmedHandler(uuid)}
        >
          Delete
        </Button>
      ) : null}
      {mode === "CREATE" || mode === "DELETE" ? (
        <Button variant="contained" onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
      ) : null}
      {mode === "VIEW" ? (
        <Button variant="contained" onClick={() => setModalOpen(false)}>
          Done
        </Button>
      ) : null}
    </div>
  )
}

export default CreateManagedTenancyModalChildren
