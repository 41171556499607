const extractArrayItemByKey = require("../../utilities/extract-array-item-by-key")

// Convert managed tenancies from DB to runtime
const makeVelocloudTenancyDbToRuntime = (velocloudTenancyDb) => {
  return {
    businessName: extractArrayItemByKey({
      array: velocloudTenancyDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "businessName",
    }).value,
    customerNetworkCode: velocloudTenancyDb.serviceCharacteristic.find(
      (obj) => obj.name === "customerNetworkCode"
    )
      ? extractArrayItemByKey({
          array: velocloudTenancyDb.serviceCharacteristic,
          keyName: "name",
          keyValue: "customerNetworkCode",
        }).value
      : null,
    tenancyNameAffix: velocloudTenancyDb.serviceCharacteristic.find(
      (obj) => obj.name === "tenancyNameAffix"
    )
      ? extractArrayItemByKey({
          array: velocloudTenancyDb.serviceCharacteristic,
          keyName: "name",
          keyValue: "tenancyNameAffix",
        }).value
      : null,
    customerIdentifier: extractArrayItemByKey({
      array: velocloudTenancyDb.relatedParty,
      keyName: "role",
      keyValue: "Customer",
    }).id,
    uuid: velocloudTenancyDb.id,
    description: velocloudTenancyDb.description,
    state: velocloudTenancyDb.state,
    tenancyProfileType: extractArrayItemByKey({
      array: velocloudTenancyDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "tenancyProfile",
    }).value.type,
    createdAt: velocloudTenancyDb.serviceDate,
    lastModified: velocloudTenancyDb.serviceDate,
    gatewayPoolId: extractArrayItemByKey({
      array: velocloudTenancyDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "tenancyProfile",
    }).value.gatewayPool,
    dealerCode: extractArrayItemByKey({
      array: velocloudTenancyDb.relatedParty,
      keyName: "role",
      keyValue: "Partner",
    }).id,
    template: extractArrayItemByKey({
      array: velocloudTenancyDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "tenancyProfile",
    }).value.template,
    contactEmail: extractArrayItemByKey({
      array: velocloudTenancyDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "tenancyProfile",
    }).value.contactEmail,
    username: extractArrayItemByKey({
      array: velocloudTenancyDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "tenancyProfile",
    }).value.adminUsername,
    vendorId: extractArrayItemByKey({
      array: velocloudTenancyDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "tenancyProfile",
    }).value.tenancyId,
    vcoUrl: extractArrayItemByKey({
      array: velocloudTenancyDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "tenancyProfile",
    }).value.url,
  }
}

module.exports = makeVelocloudTenancyDbToRuntime
