import React from "react"
import { FaTrashAlt, FaEdit } from "react-icons/fa"

const getManagedTenancyColumns = () => [
  { Header: "Business Name", accessor: "businessName" },
  { Header: "4LC (Four Letter Code)", accessor: "customerNetworkCode" },
  { Header: "Customer Identifier", accessor: "customerIdentifier" },
  { Header: "UUID", accessor: "uuid" },
  { Header: "Description", accessor: "description" },
  { Header: "State", accessor: "state" },
  {
    Header: "Profile Type",
    accessor: "tenancyProfileType",
    Cell: (props) => (
      <span>{props.value === "velocloud" ? "VeloCloud" : props.value}</span>
    ),
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    minWidth: 30,
    width: 50,
    maxWidth: 200,
    filter: "date",
    Cell: (props) => new Date(props.value).toLocaleString(),
  },
  {
    Header: "",
    accessor: "id",
    maxWidth: "100",
    disableFilters: true,
    sortable: false,
    Cell: (props) => <div className="action-icons"></div>,
  },
]

export default getManagedTenancyColumns
